/* Blog List */

.blog-list-banner-wrapper {
  width: 100%;
  height: 360px;
  background-image: url('../../public/img/blog.jpg');
  background-position: center;
  background-size: cover;
}

.blog-list-tab{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 72px;
  gap: 0 36px;
  border-bottom: 1px solid #222;
}

.blog-list-tab-menu{
  cursor: pointer;
  font-size: 16px;
  color: white;
  line-height: 100%;
  opacity: .5;
  padding: 8px 12px;
  transition: opacity 0.22s ease-out;
}

.blog-list-tab-menu:hover{
  opacity: .8;
}

.blog-list-tab-menu-selected{
  opacity: 1;
  text-decoration: underline;
  cursor: pointer;
}

.blog-list-wrapper{
  display: flex;
  justify-content: center;
  padding: 36px 16px 200px 16px;
  width: 100%;
}

.blog-list-area{
  width: 100%;
  max-width: 720px;
}

/* Post List */

.blog-list-post{
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0 36px;
  width: 100%;
  padding: 48px 0;
  border-bottom: 1px solid #222;
}

.blog-list-post:hover .blog-list-post-img{
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-filter: brightness(0.7);
  filter: brightness(0.7);
}

.blog-list-post:hover{
}

.blog-list-post-img-wrapper{
  overflow: hidden;
  width: 226px;
  height: 140px;
}

.blog-list-post-img{
  width: 100%;
  height: 100%;
  transition: all 0.33s ease-out;
}

.blog-list-post-textarea{
  width: 100%;
  max-width: 458px;
}

.blog-list-post-title{
  font-size: 24px;
  line-height: 140%;
  color: white;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-wrap:break-word; 
}

.blog-list-post-script{
  font-size: 14px;
  line-height: 160%;
  color: white;
  opacity: .7;
  margin: 14px 0 20px 0;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-wrap:break-word; 
}

.blog-list-post-subtext{
  display: flex;
  flex-direction: row;
  gap: 0 8px;
  font-size: 14px;
  line-height: 100%;
  color: white;
  opacity: .5;
}

/* Blog List Responsive Resolution*/

@media screen and (max-width: 840px){

.blog-list-tab-menu{
  padding: 8px 4  px;
}

.blog-list-wrapper{
  display: flex;
  justify-content: center;
  padding: 12px 16px 100px 16px;
  width: 100%;
}

.blog-list-post{
  padding: 36px 0;
  gap: 0 20px;
}

.blog-list-post-img-wrapper{
  overflow: hidden;
  width: 30%;
  max-width: 144px;
  height: 84px;
}

.blog-list-post-img{
  width: 100%;
  height: 100%;
}

.blog-list-banner-wrapper {
  height: 240px;
  background-image: url('../../public/img/blog-m.jpg');
}

.blog-list-tab{
  height: 56px;
}

.blog-list-post-title{
  font-size: 17px;
}

.blog-list-post-script{
  font-size: 13px;
  margin: 6px 0 10px 0;
  line-height: 150%;
}

.blog-list-post-subtext{
  font-size: 13px;
  gap: 0 4px;
}

.blog-list-post-textarea{
  width: 70%;
  max-width: none;
}

}

/* Blog Show */

.blog-show-banner-wrapper{
  width: 100%;
  height: 360px;
  background-position: center;
  background-size: cover;
}

.blog-show-banner-wrapper div{
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.3)
}

.blog-show-post-wrapper{
  width: 100%;
  padding: 100px 16px;
  display: flex;
  justify-content: center;
}

.blog-show-post-area{
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 720px;
  gap: 100px 0;
}

.blog-show-post-head{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px 0;
}

.blog-show-post-title{
  font-size: 36px;
  line-height: 140%;
  color: white;
  font-weight: 600;
}

.blog-show-post-subtext{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0 8px;
  font-size: 16px;
  opacity: .8;
}

.blog-show-post-subtext div:nth-child(3){
  padding-top: 1px;
  font-size: 16px;
  letter-spacing: .03em;
}

.blog-show-post-body{
  font-size: 16px;
  line-height: 200%;
  opacity: .8;
}

/* Blog Show Responsive Resolution */

@media screen and (max-width: 840px){

.blog-show-banner-wrapper{
  height: 240px;
}

.blog-show-post-title{
  font-size: 24px;
}

.blog-show-post-area{
  gap: 64px 0;
}

.blog-show-post-wrapper{
  padding: 64px 16px 100px 16px;
}

}

.blog-show-md-wrapper {
  width: 100%;

  img {
    width: 100%;
  }
}