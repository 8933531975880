div{
  box-sizing: border-box;
}

input[type="text"], input[type="tel"],textarea{
  box-sizing: border-box;
}

/* Wrapper & Area*/

.contact-info-wrapper{
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #111;
  padding: 160px 0 124px 0;
}

.contact-info-area{
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 64px 0;
  background: url('../../public/img/contact-logo.png') no-repeat center right;
}

.contact-form-wrapper{
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #090909;
  padding: 100px 0;
}

.contact-form-area{
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 36px 0;
}

/* Information */

.contact-info-title{
  font-size: 36px;
  font-weight: 600;
}

.contact-info-script{
  font-size: 16px;
  line-height: 160%;
  opacity: .5;
}

.contact-info-company, .contact-info-email div:nth-child(1), .contact-info-address div:nth-child(1){
  font-size: 16px;
  line-height: 100%;
}

.contact-info-email div:nth-child(2), .contact-info-address div:nth-child(2){
  font-size: 16px;
  line-height: 100%;
  opacity: .5;
}

.contact-info-company{
  margin-bottom: 20px;
}

.contact-info-email{
  margin-bottom: 16px;
}

.contact-info-email, .contact-info-address{
  display: flex;
  flex-direction: row;
}

.contact-info-email div:nth-child(1), .contact-info-address div:nth-child(1){
  width: 72px;
}

/* Form */

.contact-form-title{
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 36px;
  width: 100%;
  border-bottom: 1px solid #222;;
}

.contact-form-radio-set{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0 36px;
}

.contact-form-input-label{
  margin-bottom: 8px;
}

.contact-form-radio-selectarea{
  display: flex;
  gap: 8px 0;
}

.contact-form-radio-selectarea label{
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  line-height: 48px;
  gap: 0 8px;
}

.contact-form-radio-selectarea input[type=radio]{
 display: none;
}

.contact-form-radio-selectarea input[type=radio] + label .fake-radio{
  width: 20px;
  height: 20px;
  background: none;
  border: 1px solid #fff;
  border-radius: 10px;
  opacity: .3;
  transition: opacity .17s ease-out;
 }

 .contact-form-radio-selectarea input[type=radio]:hover + label .fake-radio{
  opacity: .5;
 }

.contact-form-radio-selectarea input[type=radio]:checked + label .fake-radio{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  background: none;
  border: 1px solid #fff;
  border-radius: 10px;
  opacity: 1;
}

.contact-form-radio-selectarea input[type=radio]:checked + label .fake-radio div{
  width: 12px;
  height: 12px;
  background: white;
  border-radius: 6px;
  opacity: 1;
}

.contact-form-input-company, .contact-form-input-name, .contact-form-input-tel, .contact-form-input-email{
  width: 100%;
}

.contact-form-input-company input, .contact-form-input-name input, .contact-form-input-tel input, .contact-form-input-email input{
  background-color: #070707;
  border: 1px solid #222;
  height: 48px;
  width: 100%;
  max-width: 280px;
  font-size: 16px;
  color: white;
  padding: 0 16px;
  outline: none;
  transition: border 0.22s ease-out;
}

.contact-form-input-company input:hover, .contact-form-input-name input:hover, .contact-form-input-tel input:hover, .contact-form-input-email input:hover{
  border: 1px solid #333;
}

.contact-form-input-company input:focus, .contact-form-input-name input:focus, .contact-form-input-tel input:focus, .contact-form-input-email input:focus{
  border: 1px solid #444;
}

.contact-form-input-text{
  width: 100%;
}

.contact-form-input-text textarea{
  resize: none;
  width: 100%;
  height: 240px;
  outline: none;
  background-color: #070707;
  border: 1px solid #222;
  color: white;
  font-size: 16px;
  font-family: 'Noto Sans';
  line-height: 200%;
  padding: 6px 16px;
  transition: border 0.22s ease-out;
}

.contact-form-input-text textarea:hover{
  border: 1px solid #333;
}

.contact-form-input-text textarea:focus{
  border: 1px solid #444;
}

.contact-form-upload button{
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding: 0 20px 0 16px;
  height: 44px;
  background: none;
  border: 1px solid #444;
  gap: 0 4px;
  transition: border 0.2s ease-in-out;
}

.contact-form-upload button:hover{
  border: 1px solid white;
}

.contact-form-upload button img{
  width: 24px;
  height: 24px;
  padding-bottom: 2px;
}

.contact-form-upload button span{
  color: white;
  font-size: 16px;
  line-height: 100%;
}

/* Submit */

.contact-form-submit-wrapper{
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 36px;
  width: 100%;
}

.contact-form-submit{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  cursor: pointer;
  background: white;
  border: none;
  height: 54px;
  width: 240px;
  font-size: 18px;
  color: #222;
  font-weight: 500;
  line-height: 100%;
  transition: all 0.22s ease-out;
}

.contact-form-submit:hover{
  opacity: .8;
}

/* Responsive Resolution */

@media screen and (max-width: 1200px){

.contact-info-wrapper{
  padding: 120px 16px 64px 16px;
}

.contact-info-area{
  width: 100%;
  max-width: 768px;
  background: none;
  gap: 48px 0;
}

.contact-info-title{
  font-size: 24px;
}

.contact-info-script{
  font-size: 14px;
}

.contact-info-company, .contact-info-email div:nth-child(1), .contact-info-address div:nth-child(1){
  font-size: 14px;
}

.contact-form-title{
  font-size: 18px;
}

.contact-info-email{
  margin-bottom: 12px;
}

.contact-form-wrapper{
  padding: 64px 16px;
}

.contact-form-area{
  max-width: 768px;
}

.contact-form-radio-set{
  flex-direction: column;
  align-items: flex-start;
}

.contact-form-submit{
  width: 100%;
  max-width: 500px;
}

}