div{
  box-sizing: border-box;
}


.business-consulting-tab-menu-wrapper{
  -ms-user-select: none; 
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

@media screen and (min-width: 1201px){ 

 /* PC Resolution */

.business-banner-wrapper {
  width: 100%;
  height: 360px;
  background-image: url('../../public/img/business.jpg');
  background-position: center;
  background-size: cover;
}

/* section wrapper */

.business-overview-wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #111;
  width: 100%;
  padding: 100px 0;
  gap: 36px 0;
}

.business-section0-wrapper{
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #070707;
  padding: 100px 0;
}

.business-section1-wrapper{
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #111;
  padding: 100px 0;
}

.business-section2-wrapper{
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #070707;
  padding: 100px 0;
}

.business-section3-wrapper{
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #111;
  padding: 100px 0;
}

.business-section0-contentarea{
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 36px 0;
}

.business-section1-contentarea{
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 36px 0;
}

.business-section2-contentarea{
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 36px 0;
}

.business-section3-contentarea{
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 36px 0;
}

/* business common */

.business-label{
  font-size: 16px;
  color: white;
  line-height: 100%;
}

.business-title-wrapper{
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 0 24px;
}

.business-title-number{
  font-size: 56px;
  font-weight: 600;
  line-height: 140%;
  color: white;
  text-align: center;
}

.business-title{
  font-size: 56px;
  font-weight: 600;
  line-height: 140%;
  color: white;
  text-align: center;
}

.business-content{
  max-width: 568px;

  font-size: 16px;
  color: white;
  opacity: .8;
  line-height: 160%;
  text-align: center;
}

/* 00 Consulting Section */

.business-consulting-tab-wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 28px;
}

.business-consulting-tab-menu-wrapper{
  display: flex;
  flex-direction: row;
  width: 100%;
  border-top: 1px solid #222;
  border-bottom: 1px solid #222;
}

.business-consulting-tab-menu-wrapper div{
  flex: 1 1 0;
}

.business-consulting-tab-menu{
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  line-height: 100%;
  gap: 0 16px;
  height: 120px;
  opacity: .5;
  transition: opacity .22s ease-out;
}

.business-consulting-tab-menu:hover{
  opacity: .8;
}

.consulting-tab-active{
  opacity: 1;
}

.business-consulting-tab-menu img{
  width: 36px;
  height: 36px;
}

/* Consulting Content */

.business-consulting-tab-content-wrapper{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 48px 0;
  gap: 0 120px;
  border-bottom: 1px solid #222;
}

.business-consulting-img{
  width: 100%;
  max-width: 1200px;
  height: 360px;
  flex: 1 1 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.business-consulting-text-wrapper{
  display: flex;
  flex-direction: column;
  gap: 12px 0;
  flex: 1 1 0;

}

.business-consulting-title{
  font-size: 24px;
  line-height: 160%;
  font-weight: 500;
  counter-reset: white;
}

.business-consulting-content{
  font-size: 16px;
  opacity: .7;
  line-height: 160%;
  white-space:pre-wrap;
}

/* Consulting Button*/

.business-consulting-btn{
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-basis: auto;
  gap: 0 10px;
  background: white;
  border: none;
  color: #222;
  padding: 12px 16px 12px 20px;
  margin-top: 12px;
  font-size: 16px;
  line-height: 100%;
  width: fit-content;
  transition: all 0.2s ease-in-out;
}

.business-consulting-btn:hover{
  gap: 0 18px;
}

.business-consulting-btn img{
  width: 16px;
  height: 16px;
}

/* 01 Business Section */

.business-section1-container{
  display: flex;
  flex-direction: column;
  gap: 64px 0;
  width: 100%;
}

.business-section1-card{
  width: 100%;
}

.business-section1-container .business-section1-card:nth-child(1){
  border-top: 1px solid #444;
}

.business-section1-card-text{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px 0;
  padding: 48px 0 36px 0;
  border-top: 1px solid #333;
}

.business-section1-card-text div:nth-child(1){
  font-size: 24px;
  font-weight: 600;
  line-height: 100%;
}

.business-section1-card-text div:nth-child(2){
  font-size: 16px;
  line-height: 160%;
  opacity: .7;
}

.business-section1-contentarea .business-content{
  margin-bottom: 64px;
}

.business-section1-card-img{
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 36px 0;
  background-color: #171717;
}

.business-section1-card-img div{
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 0;
  gap: 24px 0;
}

.business-section1-card-img p{
  font-size: 16px;
  line-height: 100%;
  margin: 0;
  padding: 0;
}

.business-section1-card-img img{
  height: 72px;
}

/* 02 Maintenance Section */

.business-section2-container{
  display: flex;
  flex-direction: column;
  gap: 64px 0;
  width: 100%;
}

.business-section2-card{
  width: 100%;
}

.business-section2-card-text{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px 0;
  padding: 48px 0 36px 0;
  border-top: 1px solid #222;
}

.business-section2-card-text div:nth-child(1){
  font-size: 24px;
  font-weight: 600;
  line-height: 100%;
}

.business-section2-card-text div:nth-child(2){
  font-size: 16px;
  line-height: 160%;
  opacity: .7;
}

.business-section2-contentarea .business-content{
  margin-bottom: 64px;
}

.business-section2-card-img{
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 36px 0;
  background-color: #171717;
}

.business-section2-card-img div{
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 0;
  gap: 24px 0;
}

.business-section2-card-img p{
  font-size: 16px;
  line-height: 100%;
  margin: 0;
  padding: 0;
}

.business-section2-card-img img{
  height: 72px;
}

/* 03 Data Visualization Section */

.business-section3-contentarea img{
  width: 100%;
  max-width: 1200px;
}

}

@media screen and (max-width: 1200px){

.container-wrapper{
  width: 100%;
}

/* Responsive Resolution */

.business-banner-wrapper {
  width: 100%;
  height: 240px;
  background-image: url('../../public/img/business.jpg');
  background-position: center;
  background-size: cover;
}

/* section wrapper */

.business-overview-wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #111;
  width: 100%;
  padding: 64px 16px;
  gap: 24px 0;
}

.business-section0-wrapper{
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #070707;
  padding: 64px 16px;
}

.business-section1-wrapper{
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #111;
  padding: 64px 16px;
}

.business-section2-wrapper{
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #070707;
  padding: 64px 16px;
}

.business-section3-wrapper{
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #111;
  padding: 64px 16px;
}

.business-section0-contentarea{
  width: 100%;
  max-width: 768px;
  min-width: 288px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px 0;
}

.business-section1-contentarea{
  width: 100%;
  max-width: 768px;
  min-width: 288px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px 0;
}

.business-section2-contentarea{
  width: 100%;
  max-width: 768px;
  min-width: 288px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px 0;
}

.business-section3-contentarea{
  width: 100%;
  max-width: 768px;
  min-width: 288px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px 0;
}

/* business common */

.business-label{
  font-size: 16px;
  color: white;
  line-height: 100%;
}

.business-title-wrapper{
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0 16px;
}

.business-title-number{
  font-size: 36px;
  font-weight: 600;
  line-height: 140%;
  color: white;
  text-align: center;
}

.business-title{
  font-size: 36px;
  font-weight: 600;
  line-height: 140%;
  color: white;
  text-align: center;
}

.business-content{
  font-size: 16px;
  color: white;
  opacity: .8;
  max-width: 768px;
  min-width: 288px;
  line-height: 160%;
  text-align: center;
}

/* 00 Consulting Section */

.business-consulting-tab-wrapper{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-top: 28px;
  gap: 24px 0;
}

.business-consulting-tab-menu-wrapper{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  min-width: 200px;
  overflow: scroll;
  border-top: 1px solid #222;
  border-bottom: 1px solid #222;
}

.business-consulting-tab-menu-wrapper::-webkit-scrollbar {
  display: none;
}

.business-consulting-tab-menu-wrapper div{
  margin-right: 36px;
}

.business-consulting-tab-menu-wrapper .business-consulting-tab-menu:nth-child(1){
  min-width: 74px;
}

.business-consulting-tab-menu-wrapper .business-consulting-tab-menu:nth-child(2){
  min-width: 92px;
}

.business-consulting-tab-menu-wrapper .business-consulting-tab-menu:nth-child(3){
  min-width: 196px;
}

.business-consulting-tab-menu{
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 100%;
  gap: 0 16px;
  padding: 28px 0;
  opacity: .5;
  transition: opacity .22s ease-out;
}

.business-consulting-tab-menu:hover{
  opacity: .8;
}

.consulting-tab-active{
  opacity: 1;
}

.business-consulting-tab-menu img{
  width: 36px;
  height: 36px;
  display: none;
}

/* Consulting Content */

.business-consulting-tab-content-wrapper{
  display: flex;
  flex-direction: column;
  gap: 24px 0;
}

.business-consulting-img{
  width: 100%;
  max-width: 1200px;
  height: 240px;
  background: url('../../public/img/consulting-company.png') no-repeat center center / cover;
}

.business-consulting-text-wrapper{
  display: flex;
  flex-direction: column;
  gap: 20px 0;
}

.business-consulting-title{
  font-size: 24px;
  line-height: 160%;
}

.business-consulting-content{
  font-size: 16px;
  opacity: .8;
  line-height: 160%;
  white-space: pre-wrap;
}

/* Consulting Button*/

.business-consulting-btn{
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-basis: auto;
  gap: 0 10px;
  background: white;
  border: none;
  color: #222;
  padding: 12px 16px 12px 20px;
  margin-top: 12px;
  font-size: 16px;
  line-height: 100%;
  width: fit-content;
  transition: all 0.2s ease-in-out;
}

.business-consulting-btn:hover{
  opacity: .9;
}

.business-consulting-btn img{
  width: 16px;
  height: 16px;
}

/* 01 Business Section */

.business-section1-container{
  display: flex;
  flex-direction: column;
  gap: 64px 0;
  width: 100%;
}

.business-section1-card{
  width: 100%;
}

.business-section1-container .business-section1-card:nth-child(1){
  border-top: 1px solid #444;
}

.business-section1-card-text{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px 0;
  padding: 48px 0 36px 0;
  border-top: 1px solid #333;
}

.business-section1-card-text div:nth-child(1){
  font-size: 24px;
  font-weight: 600;
  line-height: 100%;
}

.business-section1-card-text div:nth-child(2){
  font-size: 16px;
  line-height: 160%;
  opacity: .7;
}

.business-section1-contentarea .business-content{
  margin-bottom: 64px;
}

.business-section1-card-img{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 36px 0;
  background-color: #171717;
}

.business-section1-card-img div{
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 0;
  gap: 16px 0;
}

.business-section1-card-img p{
  text-align: center;
  font-size: 12px;
  line-height: 160%;
  margin: 0;
  padding: 0;
}

.business-section1-card-img img{
  height: 36px;
}

/* 02 Maintenance Section */

.business-section2-container{
  display: flex;
  flex-direction: column;
  gap: 64px 0;
  width: 100%;
}

.business-section2-card{
  width: 100%;
}

.business-section2-card-text{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px 0;
  padding: 48px 0 36px 0;
  border-top: 1px solid #222;
}

.business-section2-card-text div:nth-child(1){
  font-size: 24px;
  font-weight: 600;
  line-height: 100%;
}

.business-section2-card-text div:nth-child(2){
  font-size: 16px;
  line-height: 160%;
  opacity: .7;
}

.business-section2-contentarea .business-content{
  margin-bottom: 64px;
}

.business-section2-card-img{
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 36px 0;
  background-color: #171717;
}

.business-section2-card-img div{
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 0;
  gap: 16px 0;
}

.business-section2-card-img p{
  font-size: 12px;
  line-height: 160%;
  margin: 0;
  padding: 0;
}

.business-section2-card-img img{
  height: 36px;
}

/* 03 Data Visualization Section */

.business-section3-contentarea img{
  width: 100%;
  max-width: 1200px;
}

}