@import './theme.scss';

.mobile-menu-drawer-wrapper {
  position: fixed;
  z-index: $mobile-menu-drawer-index;
  top: 0px;
  right: 0px;
  max-width: 0px;
  width: 100vw;
  height: 100vh;
  background-color: #111;
  display: none;
  flex-direction: column;
  transition: 0.4s;

  @media (max-width: $grid-1200) {
    display: flex;
  }
}

.mobile-menu-drawer-header-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 16px 20px 24px;

  font-size: 16px;
  font-weight: bold;

  img {
    width: 36px;
    height: 36px;
    cursor: pointer;
  }
}

.mobile-menu-drawer-content-col {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  a {
    max-width: 100%;
  }
}

.mobile-menu-item {
  font-size: 28px;
  height: 44px;
  font-weight: bold;
  margin-bottom: 36px;
  color: white;
}
