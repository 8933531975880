@import '../stylesheets/theme.scss';

div{
  box-sizing: border-box;
}

.brand-banner-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
  width: 100%;
}

.brand-banner-container {
  width: 100%;
  height: 360px;
  background-image: url('../../public/img/brand.jpg');
  background-position: center;
  background-size: cover;
}

.brand-banner-content-section {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: -100px;
}

.brand-banner-page-title {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 36px;
}

.brand-banner-title {
  font-size: 56px;
  height: 1.4;
  font-weight: bold;
  margin-bottom: 36px;
}

.brand-banner-content {
  width: 568px;
  text-align: center;
  font-size: 16px;
  line-height: 1.6;
  opacity: .8;
}

.brand-mission-wrapper {
  display: flex;
  flex-direction: column;
}

.brand-mission-container {
  display: flex;
  flex-direction: row;
  width: 1200px;
}

.brand-mission-line {
  width: calc(50vw - 10px - 600px);
  height: 1px;
  background-color: #444;
  position: relative;
  left: calc(600px - 50vw);
  top: 8px;

  @media (max-width: $grid-1200) {
    display: none;
  }
}

.brand-mission-img {
  width: 480px;
  position: relative;
  left: calc(1200px - 480px);
}

.brand-mission-col {
  width: 1200px;
  position: absolute;
  z-index: 1;
}

.brand-mission-label {
  font-size: 16px;
  color: white;
  line-height: 1;
  font-weight: 500;
  margin-bottom: 36px;
}

.brand-mission-statement {
  font-size: 56px;
  font-weight: bold;
  color: white;
  line-height: 1.2;
  margin-bottom: 36px;
}

.brand-mission-intro {
  font-size: 16px;
  line-height: 1.6;
  opacity: 0.8;
  width: 568px;
}

.brand-vision-wrapper {
  margin-top: 200px;
  display: flex;
  flex-direction: column;
}

.brand-vision-line {
  width: calc(50vw - 600px);
  height: 1px;
  background-color: #444;
  position: relative;
  left: calc(600px - 50vw);
  top: 8px;

  @media (max-width: $grid-1200) {
    display: none;
  }
}

.brand-vision-img {
  width: 50%;
  position: relative;
  left: 0px;
  top: -24px;
}

.brand-vision-col {
  padding-left: 433px;
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: column;
}

.brand-vision-label {
  font-size: 16px;
  color: white;
  line-height: 1;
  font-weight: 500;
  margin-bottom: 36px;
}

.brand-vision-statement {
  font-size: 56px;
  font-weight: bold;
  color: white;
  line-height: 1.2;
  margin-bottom: 36px;
}

.brand-vision-intro {
  font-size: 16px;
  line-height: 1.6;
  opacity: 0.8;
  width: 568px;
}

.brand-vision-container {
  display: flex;
  flex-direction: row;
  width: 1200px;
}

.brand-corevalue-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 200px;
  margin-bottom: 200px;
}

.brand-corevalue-container {
  width: 1200px;
  display: flex;
  flex-direction: column;
}

.brand-corevalue-label {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.6;
  margin-bottom: 36px;
}

.brand-corevalue-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px 24px;
}

.brand-corevalue-block {
  padding: 48px;
  flex: 1 1 40%;
  background-color: #222;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.brand-corevalue-title {
  font-size: 48px;
  line-height: 1.2;
  font-weight: bold;
  margin-bottom: 24px;
}

.brand-corevalue-intro {
  text-align: center;
  font-size: 16px;
  line-height: 1.6;
  opacity: 0.8;
  word-break: keep-all;
}

/* Responsive Resolution */

@media screen and (max-width: 1200px){

.brand-banner-wrapper{
  margin-bottom: 24px;  
}

.brand-banner-container{
  height: 240px;
}

.brand-banner-content-section {
  top: -72px;
}

.brand-banner-page-title {
  margin-bottom: 24px;
}

.brand-banner-title {
  font-size: 36px;
  margin-bottom: 24px;
}

.brand-banner-content {
  width: 100%;
  text-align: center;
  font-size: 16px;
  line-height: 1.6;
  opacity: .8;
}

.brand-mission-wrapper {
  position: relative;
  width: 100%;
  max-width: 768px;
  padding: 0 16px;
  margin-top: 0px;
}

.brand-mission-container{
  width: 100%;
  justify-content: center;
}

.brand-mission-col{
  bottom: -20px;
  width: 90%;
  max-width: 768px;;
}

.brand-mission-img{
  position: static;
  width: 50%;
}

.brand-mission-label{
  margin-bottom: 24px;
}

.brand-mission-statement{
  margin-bottom: 24px;
  font-size: 36px;
}

.brand-mission-intro{
  width: 100%;
}

.brand-vision-wrapper {
  position: relative;
  width: 100%;
  max-width: 768px;
  padding: 0 16px;
  margin-top: 120px;
}

.brand-vision-container{
  width: 100%;
  justify-content: center;
}

.brand-vision-col{
  bottom: -20px;
  padding: 0;
  width: 90%;
  max-width: 768px;;
}

.brand-vision-img{
  position: static;
  width: 50%;
}

.brand-vision-label{
  margin-bottom: 24px;
}

.brand-vision-statement{
  margin-bottom: 24px;
  font-size: 36px;
}

.brand-vision-intro{
  width: 100%;

}

.brand-corevalue-wrapper {
  padding: 0 16px;
  margin-top: 120px;
  margin-bottom: 120px;
}

.brand-corevalue-container {
  max-width: 768px;
  width: 100%;
}

.brand-corevalue-block{
  padding: 32px;
}

.brand-corevalue-label{
  margin-bottom: 16px;
}

.brand-corevalue-title{
  font-size: 32px;
}

}

@media screen and (max-width: 560px){

  .brand-mission-img{
    position: static;
    width: 90%;
    -webkit-filter: brightness(0.7);
    filter: brightness(0.7);
    
  }

  .brand-vision-img{
    position: static;
    width: 90%;
    -webkit-filter: brightness(0.7);
    filter: brightness(0.7);
  }

  .brand-corevalue-row {
    flex-direction: column;
  }

}