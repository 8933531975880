@import './theme.scss';

.main-banner-wrapper {
  width: 100%;
  height: 640px;
  background-image: url('../../public/img/main.jpg');
  background-position: center;
  background-size: cover;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: $grid-1200) {
    padding: 180px 16px 140px 16px;
    height: fit-content;
  }

  @media (max-width: $grid-480) {
    
    background-position: bottom 200px;
  }
}

.main-banner-container {
  width: $grid-1200;

  @media (max-width: $grid-1200) {
    width: $grid-768;
  }

  @media (max-width: $grid-768) {
    width: calc(100vw - 32px);
  }
}

.main-banner-title {
  font-size: 36px;
  line-height: 140%;
  color: white;
  font-weight: 600;
  margin-bottom: 28px;
  
  @media (max-width: $grid-480) {
    font-size: 28px;
  }
}

.main-banner-content {
  font-size: 14px;
  line-height: 200%;
  color: white;
  margin-bottom: 28px;

  @media (max-width: $grid-480) {
    white-space: pre-wrap;
  }
}

.main-link-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  text-decoration: underline;
  color: white;

  &:hover {
    img {
      margin-left: 16px;
    }
  }

  img {
    transition: 0.3s;
    margin-left: 10px;
    width: 16px;
    height: 16px;
  }
}

.main-brand-section {
  width: 100%;
  padding: 100px 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media (max-width: 480px) {
    padding: 0px 0px 88px 0px;
  }
}

.main-brand-container {
  width: 1200px;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: $grid-1200) {
    width: $grid-768;
    flex-direction: column;
  }

  @media (max-width: $grid-768) {
    width: calc(100vw - 32px);
  }
}

.main-brand-img {
  width: 493px;
  height: 493px;
  @media (max-width: $grid-1200) {
    width: 360px;
    height: 360px;
  }

  @media (max-width: 360px) {
    width: 100vw;
    height: auto;
  }
}

.main-brand-content-section {
  max-width: 580px;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-left: 93px;
  @media (max-width: $grid-1200) {
    max-width: 490px;
    padding-left: 0px;
    align-items: center;
  }
}

.main-brand-title {
  font-size: 48px;
  line-height: 100%;
  color: white;
  font-weight: 600;
  margin-bottom: 28px;

  @media (max-width: $grid-1200) {
    text-align: center;
    line-height: 1.2;
  }

  @media (max-width: $grid-480) {
    font-size: 28px;
    text-align: center;
  }
}

.main-brand-content {
  font-size: 14px;
  line-height: 160%;
  color: white;
  margin-bottom: 28px;
  @media (max-width: $grid-1200) {
    text-align: center;
  }
}

.main-consulting-wrapper{
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #171717;
  padding: 80px 16px;
}

.main-consulting-container{
  width: 100%;
  max-width: 1200px;
  @media (max-width: $grid-1200) {
    max-width: 768px;
  }
}

.main-consulting-label{
  font-size: 20px;
}

.main-dbasin-section {
  width: 100%;
  height: 517px;
  background-image: url('../../public/img/main-dbasin.png');
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: $grid-1200) {
    height: 352px;
  }
}

.main-dbasin-container {
  width: 100%;
  height: 100%;
  background-color: rgba(68, 68, 68, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.main-dbasin-logo {
  width: 246px;
  height: 160px;
  @media (max-width: $grid-1200) {
    width: 176px;
    height: 114px;
  }
}

.main-dbasin-comming-soon {
  margin-top: 36px;
  font-size: 16px;
  opacity: 0.8;
}

.main-dbasin-intro {
  margin-top: 48px;
  opacity: 0.8;
  text-align: center;
  width: 376px;
  font-size: 14px;
  line-height: 1.6;
  
  @media (max-width: $grid-480) {
    width: calc(100vw - 32px);
  }
}

.main-blog-section {
  padding: 120px 0px;
  width: 100%;
  background-color: #171717;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: $grid-1200) {
    padding: 48px 0px 0px 0px;
  }
}

.main-blog-container {
  width: 1200px;
  margin-bottom: 38px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: $grid-1200) {
    width: 768px;
  }

  @media (max-width: $grid-768) {
    width: calc(100vw - 32px);
  }
}

.main-blog-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.main-blog-row {
  margin-top: 38px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: $grid-1200) {
    flex-direction: column;
  }
}

.main-blog-item {
  display: flex;
  flex-direction: column;
  width: 358px;
  cursor: pointer;

  @media (max-width: $grid-1200) {
    width: $grid-768;
    margin-bottom: 64px;
  }

  @media (max-width: $grid-768) {
    width: calc(100vw - 32px);
    margin-bottom: 48px;
  }
}

.main-blog-thumbnail {
  width: 358px;
  height: 228px;
  background-position: center;
  background-size: cover;
  margin-bottom: 24px;

  @media (max-width: $grid-1200) {
    width: $grid-768;
  }

  @media (max-width: $grid-768) {
    width: calc(100vw - 32px);
  }
}

.main-blog-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}

.main-blog-content {
  font-size: 14px;
  opacity: 0.6;
  line-height: 1.6;
  margin-bottom: 20px;
  height: 45px;
  overflow: hidden;
}

.main-blog-author {
  font-size: 14px;
  line-height: 1.6;
  opacity: 0.6;
}

.main-blog-label {
  font-size: 20px;
}

.main-recruit-section {
  padding: 100px 0px;
  width: 100%;
  background-color: #111;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: $grid-1200) {
    padding: 64px 0px;
  }
}

.main-recruit-label {
  font-size: 20px;
  margin-bottom: 24px;
}

.main-recruit-title {
  font-size: 48px;
  font-weight: bold;
  line-height: 1.4;
  text-align: center;
  @media (max-width: $grid-768) {
    font-size: 28px;
    padding: 0px 16px;
  }
}

.main-recruit-content {
  margin-top: 24px;
  margin-bottom: 38px;
  font-size: 14px;
  opacity: 0.8;
  text-align: center;
  line-height: 1.6;
}

.main-contact-section {
  padding: 80px 0px;
  width: 100%;
  background-color: #171717;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: $grid-1200) {
    padding: 64px 0px;
  }
}

.main-contact-title {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 28px;

  @media (max-width: $grid-1200) {
    font-size: 28px;
  }
}

.main-contact-content {
  font-size: 14px;
  text-align: center;
  line-height: 1.6;
  opacity: 0.8;
  margin-bottom: 28px;
}