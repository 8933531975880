@import './theme.scss';

.nav-wrapper {
  width: 100%;
  padding: 16px 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.3s ease-out;
}

.nav-container {
  width: $grid-1200;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  a {
    display: flex;
    align-items: center;
  }

  @media (max-width: $grid-1200) {
    width: $grid-768;
  }

  @media (max-width: $grid-768) {
    padding: 0px 16px;
    width: 100vw;
  }
}

.nav-logo {
  height: 17px;
}

.nav-row {
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: $grid-1200) {
    display: none;
  }
}

.nav-mobile-menu-button {
  display: none;
  width: 36px;
  height: 36px;
  cursor: pointer;

  @media (max-width: $grid-1200) {
    display: flex;
  }
}

.nav-item {
  height: 1.2;
  font-size: 14px;
  color: white;
  margin-left: 48px;
  font-family: 'Red Hat Display', sans-serif;
}

.nav-item-active {
  height: 1.2;
  font-size: 14px;
  color: white;
  margin-left: 48px;
  font-family: 'Red Hat Display', sans-serif;
  text-decoration: underline;
}

.nav-item-divider {
  margin: 0px 48px;
  width: 1px;
  height: 16px;
  background-color: white;
  opacity: 0.3;
}

.nav-item-button {
  color: white;
  border: solid 1px white;
  font-family: 'Red Hat Display', sans-serif;
  height: 1.2;
  font-size: 14px;
  padding: 8px 14px;
}