.scroll-container {
  width: 16px;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.05);
  @media (max-width: 480px) {
    display: none;
  }
}

.scroll-div {
  transition: 0.3s;
  position: fixed;
  margin: 0px 4px;
  border-radius: 4px;
  width: 8px;
  background-color: rgba(255, 255, 255, 0.1);
}
