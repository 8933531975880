@import './colors.scss';

body {
  color: white;
  background-color: $black;
  margin: 0;
  font-family: 'Red Hat Display', 'Noto Sans KR', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

}

body::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
  padding: 0px;
  color: white;
  // font-weight: bold;
}

div {
  word-break: keep-all;
  white-space: pre-wrap;
}

p {
  white-space: pre-wrap;
}
