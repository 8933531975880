@import './theme.scss';

.footer-wrapper {
  background-color: black;
  padding: 120px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Noto Sans KR', sans-serif;

  @media (max-width: $grid-1200) {
    padding: 48px 0px 64px 0px;
  }
}

.footer-container {
  width: $grid-1200;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: $grid-1200) {
    width: auto;
    align-items: center;
  }
}

.footer-item-row {
  display: flex;
  align-items: center;

  @media (max-width: $grid-1200) {
    margin-top: 12px;
  }
}

.footer-row-1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;

  @media (max-width: $grid-1200) {
    flex-direction: column;
    align-items: center;
  }

  .text-1 {
    color: white;
  }

  .text-2 {
    margin-left: 8px;
    color: white;
    opacity: 0.8;
  }

  .divider-1 {
    background-color: #444;
    margin: 0px 16px;
    width: 1px;
    height: 12px;

    @media (max-width: $grid-1200) {
      display: none;
    }
  }
}

.footer-copyright {
  margin-top: 20px;
  font-size: 14px;
  line-height: 100%;
  color: white;
  opacity: 0.8;
  font-family: 'Red Hat Display', sans-serif;
}