.recruit-list-banner-wrapper{
  width: 100%;
  height: 360px;
  background-image: url('../../public/img/recruit.jpg');
  background-position: center;
  background-size: cover;
}

.recruit-list-all-wrapper{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.recruit-list-overview-area{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  gap: 36px 0;
  padding: 100px 16px 164px 16px;
}

.recruit-list-page-title{
  font-size: 16px;
  font-weight: 500;
  line-height: 100%;
}

.recruit-list-title{
  text-align: center;
  font-size: 56px;
  font-weight: 600;
  line-height: 140%;
}

.recruit-list-content{
  max-width: 600px;
  text-align: center;
  font-size: 16px;
  line-height: 160%;
  opacity: .8;
}

.recruit-list-section1-area, .recruit-list-section2-area, .recruit-list-section3-area, .recruit-list-section4-area{
  width: 100%;
  max-width: 1200px;
}

.recruit-list-section1-area{
  margin-bottom: 48px;
}

.recruit-list-section2-area{
  margin-bottom: 120px;
}

.recruit-list-section3-area{
  margin-bottom: 200px;
}

.recruit-list-section4-area{
  margin-bottom: 120px;
}

.recruit-list-section1-scriptarea{
  position: relative;
  top: -32px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 24px 0;
  padding-left: 24px;
}

.recruit-list-section1-title, .recruit-list-section2-title, .recruit-list-section3-title, .recruit-list-section4-title{
  display: flex;
  flex-direction: row;
  align-content: center;
  gap: 0 20px;
  font-size: 48px;
  font-weight: 600;
}

.recruit-list-section1-script{
  max-width: 315px;
  font-size: 14px;
  line-height: 160%;
  opacity: .7;
}

.recruit-list-section1-img{
  width: 640px;
  padding-bottom: 35%;
  background: url('../../public/img/corevalue-1.jpg') no-repeat center center/cover;
}

.recruit-list-section2-area{
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.recruit-list-section2-scriptarea{
  position: absolute;
  left: 420px;
  top: 120px;
  display: flex;
  flex-direction: column;
  width: 780px;
  padding-top: 64px;
  border-top: 1px solid rgba(255,255,255,.3);
  gap: 24px 0;
}

.recruit-list-section2-script{
  max-width: 315px;
  font-size: 14px;
  line-height: 160%;
  opacity: .7;
}

.recruit-list-section2-img{
  width: 420px;
  padding-bottom: 45%;
  background: url('../../public/img/corevalue-2.jpg') no-repeat center center/cover;
}

.recruit-list-section3-area{
  display: flex;
  flex-direction: row;
  position: relative;
}

.recruit-list-section3-scriptarea{
  z-index: 10;
  position: absolute;
  left: 0px;
  top: 48px;
  display: flex;
  flex-direction: column;
  width: 1200px;
  padding-top: 64px;
  padding-left: 640px;
  border-top: 1px solid rgba(255,255,255,.3);
  gap: 24px 0;
}

.recruit-list-section3-script{
  max-width: 315px;
  font-size: 14px;
  line-height: 160%;
  opacity: .7;
}

.recruit-list-section3-img{
  z-index: 1;
  width: 45%;
  padding-bottom: 35%;
  background: url('../../public/img/corevalue-3.jpg') no-repeat center center/cover;
}

.recruit-list-section4-scriptarea{
  z-index: 10;
  display: flex;
  flex-direction: column;
  margin-top: 36px;
  gap: 24px 0;
}


.recruit-list-section4-script{
  max-width: 560px;
  font-size: 14px;
  line-height: 160%;
  opacity: .7;
}

.recruit-list-section4-img{
  z-index: 1;
  width: 100%;
  padding-bottom: 30%;
  background: url('../../public/img/corevalue-4.jpg') no-repeat center center/cover;
}

/* Jot Advertisement */

.recruit-list-ad-area{
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
  padding: 120px 0 200px 0;
  border-bottom: 1px solid #222;
  gap: 24px 0;
}

.recruit-list-ad-label-wrapper{
  display: flex;
  flex-direction: row;
  gap: 0 12px;
}

.recruit-list-ad-label{
  display: flex;
  width: fit-content;
  padding: 4px 10px;
  background-color: rgba(25, 255, 105, .08);
  transition: all 0.1s ease-in-out;
  color: rgb(25, 255, 105);
  margin-bottom: 4px;
}

.recruit-list-ad-end .recruit-list-ad-label{
  display: flex;
  width: fit-content;
  padding: 4px 10px;
  background-color: #333;
  transition: all 0.1s ease-in-out;
  color: #666;
  margin-bottom: 4px;
}

.recruit-list-ad-name{
  font-size: 20px;
}

.recruit-list-ad-wrapper{
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px 24px;
}

.recruit-list-ad{
  cursor: pointer;
  display: flex;
  flex-direction: column;
  flex: 1 1 40%;
  background-color: #222;
  padding: 36px;
  gap: 16px 0;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  transition: all 0.15s ease-in-out;
}

.recruit-list-ad-end{
  cursor: pointer;
  display: flex;
  flex-direction: column;
  flex: 1 1 40%;
  background-color: #222;
  padding: 36px;
  gap: 16px 0;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  transition: all 0.15s ease-in-out;
}

.recruit-list-ad-end .recruit-list-ad-title, .recruit-list-ad-end .recruit-list-ad-subtitle, .recruit-list-ad-end .recruit-list-ad-date{
  color: #555;
}

.recruit-list-ad:hover{
  background-color: #272727;
  box-shadow: 0 16px 64px -12px rgba(0,0,0,.8);
}
/*
.recruit-list-ad:hover .recruit-list-ad-label{
  background-color: #373737;
}
*/
.recruit-list-ad-title{
  font-size: 20px;
  font-weight: 600;
  line-height: 100%;
}

.recruit-list-ad-subtitle{
  font-size: 16px;
  line-height: 100%;
  opacity: .7;
}

.recruit-list-ad-date{
  font-size: 14px;
  line-height: 100%;
  opacity: .5;
  text-align: right;
}

/* Responsive Resolution */

@media screen and (max-width: 1200px){

.recruit-list-banner-wrapper{
  height: 240px;
}

.recruit-list-overview-area{
  max-width: none;
  gap: 24px 0;
  padding: 100px 16px 100px 16px;
}

.recruit-list-title{
  font-size: 36px;
  line-height: 120%;
}

.recruit-list-section1-area, .recruit-list-section2-area, .recruit-list-section3-area, .recruit-list-section4-area{
  width: 100%;
}

.recruit-list-section1-title, .recruit-list-section2-title, .recruit-list-section3-title, .recruit-list-section4-title{
  font-size: 32px;
}

.recruit-list-section1-img{
  width: 80%;
  padding-bottom: 45%;
  background: url('../../public/img/corevalue-1.jpg') no-repeat center center/cover;
}

.recruit-list-section1-area{
  margin-bottom: 100px;
}

.recruit-list-section1-scriptarea{
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 0;
  gap: 16px 0;
  margin-top: 36px;
  padding: 0 16px;
  width: 100%;
}

.recruit-list-section1-title, .recruit-list-section1-script{
  width: 100%;
  max-width: 768px;
}

.recruit-list-section1-script div{
  max-width: 400px;
}

.recruit-list-section2-area{
  justify-content: flex-start;
  align-items: flex-end;
  flex-direction: column;
  gap: 0;
  margin-bottom: 100px;
}

.recruit-list-section2-img{
  width: 50%;
  padding-bottom: 65%;
  background: url('../../public/img/corevalue-2.jpg') no-repeat center center/cover;
}

.recruit-list-section2-scriptarea{
  align-items: center;
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  padding-top: 36px;
  padding-left: 16px;
  gap: 16px 0;
}

.recruit-list-section2-title, .recruit-list-section2-script{
  width: 100%;
  max-width: 768px;
}

.recruit-list-section3-area{
  flex-direction: column;
  margin-bottom: 120px;
}

.recruit-list-section3-img{
  width: 70%;
  padding-bottom: 45%;
  background: url('../../public/img/corevalue-3.jpg') no-repeat center center/cover;
}

.recruit-list-section3-scriptarea{
  align-items: center;
  position: static;
  width: 100%;
  margin-top: 36px;
  padding: 0 16px;
  border-top: none;
  gap: 16px 0;
}

.recruit-list-section3-title, .recruit-list-section3-script{
  width: 100%;
  max-width: 768px;
}

.recruit-list-section3-script div{
  max-width: 400px;
}

.recruit-list-section4-area{
  margin-bottom: 100px;
}

.recruit-list-section4-img{
  width: 100%;
  padding-bottom: 35%;
  background: url('../../public/img/corevalue-4.jpg') no-repeat center center/cover;
}

.recruit-list-section4-scriptarea{
  padding: 0 16px;
  align-items: center;
  text-align: center;
}

.recruit-list-section4-scriptarea div{
  max-width: 400px;
}

.recruit-list-section4-title{
  flex-direction: column;
  line-height: 120%;
  gap: 16px 0;
}

/* Job Advertisement */

.recruit-list-ad-area{
  align-items: center;
  width: 100%;
  max-width: none;
  padding: 72px 16px 120px 16px;
  gap: 24px 0;
}

.recruit-list-ad-label{
  font-size: 14px;
  padding: 4px 8px;
}

.recruit-list-ad-wrapper{
  gap: 16px 0;
  max-width: 768px;
}

.recruit-list-ad{
  padding: 24px;
  flex: 1 1 80%;
  gap: 12px 0;
}

.recruit-list-ad-name{
  font-size: 16px;
}

.recruit-list-ad-title{
  font-size: 16px;
}

.recruit-list-ad-subtitle{
  font-size: 14px;
}

.recruit-list-ad-date{
  font-size:12px;
}

}

/* Recruit Show */

.recruit-show-wrapper{
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 136px 16px;
}

.recruit-show-area{
  width: 100%;
  max-width: 1200px;
}

.recruit-show-head{
  padding: 64px 0;
  border-top: 1px solid #333;
}

.recruit-show-label{
  background-color: rgba(25, 255, 105, .08);
  padding: 9px 16px 8px 16px;
  width: fit-content;
  font-size: 16px;
  line-height: 100%;
  margin-bottom: 32px;
  color: rgb(25, 255, 105);
}

.recruit-show-label-end{
  background-color: #222;
  color: #555;
  padding: 9px 16px 8px 16px;
  width: fit-content;
  font-size: 16px;
  line-height: 100%;
  margin-bottom: 32px;
}

.recruit-show-title{
  font-size: 48px;
  line-height: 120%;
  font-weight: 600;
  margin-bottom: 12px;
}

.recruit-show-subtitle{
  font-size: 20px;
  line-height: 140%;
  margin-bottom: 36px;
}

.recruit-show-date{
  font-size: 16px;
  opacity: .5;
}

.recruit-show-body{
  width: 100%;
  padding: 64px 0;
  border-top: 1px solid #333;
  font-size: 16px;
  line-height: 200%;
  opacity: .7;
}

.recruit-show-btn-wrapper{
  display: flex;
  justify-content: center;
  width: 100%;
  border-top: 1px solid #333;
  padding: 64px 0;
}

.recruit-show-btn-wrapper button{
  cursor: pointer;
  background-color: white;
  border: none;
  width: 240px;
  padding: 21px 0 19px 0;
  font-size: 20px;
  font-weight: 500;
  color: #222;
  transition: all 0.22s ease-out;;
}

.recruit-show-btn-wrapper button:hover{
  opacity: .8;
}

/* Recruit Show Responsive Resolution */

@media screen and (max-width: 1200px){

.recruit-show-wrapper{
  padding: 136px 16px;
}

.recruit-show-area{
  max-width: 768px;
}

.recruit-show-head{
  padding: 48px 0;
}

.recruit-show-label{
  padding: 9px 16px 8px 16px;
  font-size: 14px;
  margin-bottom: 24px;
}

.recruit-show-title{
  font-size: 32px;
  margin-bottom: 12px;
}

.recruit-show-subtitle{
  font-size: 16px;
  margin-bottom: 24px;
}

.recruit-show-date{
  font-size: 14px;
}

.recruit-show-body{
  padding: 48px 0;
}

.recruit-show-btn-wrapper{
  padding: 48px 0;
}

.recruit-show-btn-wrapper button{
  width: 100%;
  max-width: 420px;
  padding: 17px 0 15px 0;
  font-size: 18px;
}

}

/* Rcruit Submit */

.recruit-submit-wrapper{
  width: 100%;
  padding: 200px 16px;
  display: flex;
  justify-content: center;
}

.recruit-submit-area{
  width: 100%;
  max-width: 1200px;
}

.recruit-submit-head{
  display: flex;
  flex-direction: column;
  gap: 64px;
}

.recruit-submit-info{
  display: flex;
  flex-direction: column;
  background-color: #222;
  padding: 48px 48px;
}

.recruit-submit-info-title{
  font-size: 24px;
  font-weight: 600;
  line-height: 120%;
  margin-bottom: 12px;
}

.recruit-submit-info-subtitle{
  font-size: 16px;
  line-height: 140%;
  margin-bottom: 36px;
  opacity: .8;
}

.recruit-submit-info-date{
  font-size: 14px;
  line-height: 100%;
  opacity: .7;
}

.recruit-submit-title{
  font-size: 48px;
  font-weight: 600;
}

.recruit-submit-notice{
  font-size: 16px;
  padding: 48px 0 36px 0;
  border-bottom: 1px solid #272727;
}

.recruit-submit-name, .recruit-submit-mobile, .recruit-submit-resume, .recruit-submit-portfolio, .recruit-submit-introduce{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.recruit-submit-introduce{
  border-bottom: 1px solid #222;
}

.recruit-submit-name-wrapper, .recruit-submit-mobile-wrapper, .recruit-submit-resume-wrapper, .recruit-submit-portfolio-wrapper{
  border-bottom: 1px solid #222;
  padding :48px 0;
  width: 100%;
}

.recruit-submit-introduce-wrapper, .recruit-submit-btn-wrapper{
  padding :48px 0 0 0;
  width: 100%;
}

.recruit-submit-btn-wrapper{
  display: flex;
  justify-content: center;
}

.recruit-submit-name-label, .recruit-submit-mobile-label, .recruit-submit-resume-label, .recruit-submit-portfolio-label, .recruit-submit-introduce-label{
  width: 200px;
}

.recruit-submit-name-wrapper input, .recruit-submit-mobile-wrapper input{
  background-color: #070707;
  border: 1px solid #222;
  width: 240px;
  font-size: 16px;
  height: 48px;
  padding: 0 12px;
  outline: none;
  color: white;
  transition: border 0.22s ease-out;
}

.recruit-submit-name-wrapper input:hover, .recruit-submit-mobile-wrapper input:hover, .recruit-submit-introduce-textarea:hover{
  border: 1px solid #333;
}

.recruit-submit-name-wrapper input:focus, .recruit-submit-mobile-wrapper input:focus, .recruit-submit-introduce-textarea:focus{
  border: 1px solid #444;
}

.recruit-submit-resume-wrapper button, .recruit-submit-portfolio-wrapper button {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0 4px;
  cursor: pointer;
  background: none;
  border: 1px solid #444;
  padding: 2px 20px 0 16px;
  height: 44px;
  font-size: 16px;
  color: white;
  transition: border 0.2s ease-in-out;
  width: 140px;
}

.recruit-submit-resume-wrapper button:hover, .recruit-submit-portfolio-wrapper button:hover{
  border: 1px solid #fff;
}

.recruit-submit-resume-wrapper img, .recruit-submit-portfolio-wrapper img {
  width: 24px;
  margin-bottom: 2px;
}

.recruit-submit-portfolio{
  display: flex;
  align-items: flex-start;
}

.recruit-submit-portfolio-label{
  padding-top: 56px;
}

.recruit-submit-portfolio-wrapper{
  display: flex;
  flex-direction: column;
  gap: 24px 0;
}

.recruit-submit-portfolio-input{
  display: flex;
  flex-direction: row;
  gap: 0 36px;
}


.recruit-submit-portfolio-radio-selectarea input{
  display: none;
}

.recruit-submit-portfolio-radio-selectarea input[type=radio] + label{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0 12px;
  height: 44px;
  cursor: pointer;
}

.recruit-submit-portfolio-radio-selectarea input[type=radio] + label .fake-radio{
  width: 20px;
  height: 20px;
  background: none;
  border: 1px solid #fff;
  border-radius: 10px;
  opacity: .3;
  transition: opacity .17s ease-out;
 }

 .recruit-submit-portfolio-radio-selectarea input[type=radio]:hover + label .fake-radio{
  opacity: .5;
 }

.recruit-submit-portfolio-radio-selectarea input[type=radio]:checked + label .fake-radio{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  background: none;
  border: 1px solid #fff;
  border-radius: 10px;
  opacity: 1;
}

.recruit-submit-portfolio-radio-selectarea input[type=radio]:checked + label .fake-radio div{
  width: 12px;
  height: 12px;
  background: white;
  border-radius: 6px;
  opacity: 1;
}

.recruit-submit-portfolio-wrapper button{
  cursor: pointer;
  background: none;
  border: 1px solid #444;
  padding: 2px 20px 0 16px;
  height: 44px;
  font-size: 16px;
  color: white;
}

.recruit-submit-introduce{
  align-items: flex-start;
}

.recruit-submit-introduce-label{
  padding-top: 48px;
}

.recruit-submit-introduce-textarea-wrapper{
  display: flex;
  flex-direction: column;
  gap: 0;
}

.recruit-submit-introduce-textarea{
  font-family: 'Noto Sans';
  font-size: 16px;
  line-height: 200%;
  padding: 8px 12px;
  width: 100%;
  height: 240px;
  resize: none;
  background-color: #070707;
  outline: none;
  border: 1px solid #222;
  transition: border 0.22s ease-out;
  color: white;
  margin-bottom: 0;
}

.recruit-submit-introduce-subject-text{
  opacity: .7;
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 16px;
}

.recruit-submit-introduce-length{
  display: flex;
  justify-content: flex-end;
  color: rgba(255, 255, 255, .5);
  font-size: 12px;
  width: 100%;
  background-color: #070707;
  margin-bottom: 48px;
  padding: 12px;
  border-right: 1px solid #222;
  border-bottom: 1px solid #222;
  border-left: 1px solid #222;
}

.recruit-submit-btn-wrapper button{
  cursor: pointer;
  width: 240px;
  height: 60px;
  background-color: white;
  color: #222;
  border: none;
  font-size: 18px;
  font-weight: 500;
  transition: all 0.22s ease-out;;
}

.recruit-submit-btn-wrapper button:hover{
  opacity: .8;
}

@media screen and (max-width: 768px){

.recruit-submit-wrapper{
  padding: 160px 16px;
}
  
.recruit-submit-head{
  gap: 48px;
}

.recruit-submit-title{
  font-size: 36px;
}

.recruit-submit-info{
  border-top: 4px solid #fff;
  border-bottom: 4px solid #fff;
  background: none;
  padding: 36px 0;
}

.recruit-submit-info-title{
  font-size: 24px;
  margin-bottom: 8px;
}

.recruit-submit-info-subtitle{
  font-size: 16px;
  margin-bottom: 24px;
}

.recruit-submit-notice{
  padding: 36px 0;
}

.recruit-submit-name, .recruit-submit-mobile, .recruit-submit-resume, .recruit-submit-portfolio, .recruit-submit-introduce{
  flex-direction: column;
  align-items: flex-start;
  padding-top: 24px;
}

.recruit-submit-name-wrapper, .recruit-submit-mobile-wrapper, .recruit-submit-resume-wrapper, .recruit-submit-portfolio-wrapper, .recruit-submit-introduce-wrapper{
  border-bottom: 1px solid #171717;
  padding : 8px 0 24px 0;
  width: 100%;
}

.recruit-submit-portfolio-label, .recruit-submit-introduce-label{
  padding: 0;
}

.recruit-submit-introduce-label{
  margin-bottom: 16px;
}

.recruit-submit-btn-wrapper button{
  width: 100%;
}

}