@import './theme.scss';

.container-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.container-nav-wrapper {
  z-index: $nav-index;
  position: fixed;
  top: 0px;
  width: 100%;
}

.container-children-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

.container-scroll-wrapper {
  position: fixed;
  top: 0px;
  right: 0px;
  z-index: $scroll-index;
}
